import React from 'react';

const Stack = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120" role="img">
    <path fill="currentColor" class="st0" d="M22.4 57.5h74.8v15.4H22.4z" />
    <path fill="currentColor" class="st1" d="M22.4 37.6h74.8V53H22.4z" />
    <path
      fill="currentColor"
      class="st2"
      d="M85.5 17H34.4c-6.6 0-12 5.5-12 12.3v4h74.8v-4C97.2 22.5 92 17 85.5 17z"
    />
    <path
      fill="currentColor"
      class="st3"
      d="M22.4 77.3v4c0 6.8 5.4 12.3 12 12.3h32v16.3l15.8-16.3h3.5c6.6 0 12-5.5 12-12.3v-4H22.4z"
    />
  </svg>
);

export default Stack;
